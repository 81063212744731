import { BigNumber, BigNumberInWei } from '@injectivelabs/utils'
import { getDerivatives } from 'app/classes/Derivative'
import { Web3Exception } from '@injectivelabs/exceptions'
import { httpConsumer } from 'app/singletons/HttpConsumer'
import {
  basicDataToUiBasicData,
  chartDataToUiChartData,
  grpcDelegatorsToUiDelegators,
  grpcUnBondingDelegatorsToUiUnBondingDelegators,
  grpcDelegationRewardToUiReward,
  networkStatusToUiNetworkStatus,
  validatorsToUiValidators,
  proposalsToUiProposals,
  govParamsToUiGovParams,
  proposalToUiProposal,
  grpcCoinsSupplyToUiCoins,
  grpcReDelegatorsToUiReDelegators,
  insuranceFundsToUiInsuranceFunds,
  oraclesToUiOracles
} from 'app/transformers/staking'
import {
  CHAIN_ID,
  MAINNET_CHAIN_ID,
  TESTNET_CHAIN_ID,
  TESTNET_GAS_PRICE,
  UNLIMITED_ALLOWANCE_IN_BASE_UNITS,
  ZERO_IN_WEI
} from 'app/utils/constants'
import {
  getAddressFromCosmosAddress,
  getCosmosAddressFromAddress,
  getTransactionOptions
} from 'app/utils/transactions'
import { getWeb3Strategy, initWeb3Strategy } from 'app/web3'
import { stakingConsumer } from 'app/singletons/StakingConsumer'
import { getContracts } from 'app/singletons/Contracts'
import { BaseCurrencyContract } from '@injectivelabs/contracts/dist/contracts/BaseCurrency'
import { Wallet } from '@injectivelabs/web3-strategy'
import {
  DistributionComposer,
  ExchangeProposalComposer,
  GovernanceComposer,
  InsuranceComposer,
  PeggyComposer,
  StakingComposer
} from '@injectivelabs/chain-consumer'
import { ProposalStatus } from '@injectivelabs/chain-api/cosmos/gov/v1beta1/gov_pb'
import { ChainId } from '@injectivelabs/ts-types'
import { transactionConsumer } from 'app/singletons/TransactionConsumer'
import { getTestnetContracts } from 'app/singletons/TestnetContracts'
import { govConsumer } from 'app/singletons/GovConsumer'
import { oracleConsumer } from 'app/singletons/OracleExchangeConsumer'
import { bankConsumer } from 'app/singletons/BankConsumer'
import { generatePaginationFromKey } from 'app/utils/generators'
import { paginationUint8ArrayToString } from 'app/transformers/pagination'
import { distributionConsumer } from 'app/singletons/distributionConsumer'
import { insuranceConsumer } from '../singletons/InsuranceConsumer'
import { cosmosSdkDecToBigNumber } from '../transformers'
import { insuranceFundConsumer } from '../singletons/InsuranceExchangeConsumer'
import { waitTransactionSuccess } from './blockchain'
import {
  AccountAddress,
  CosmosDetails,
  ProposalStatusNumber,
  SpotMarketLaunchProposal,
  TokenType,
  TokenWithBalance,
  UiCoin,
  UiGovParams,
  UiProposal,
  UiValidator,
  UiSingleProposal,
  UiSupplyCoin,
  VoteOptionNumber,
  UiUnBondingDelegator,
  UiReDelegator,
  Pagination,
  UiInsuranceParams,
  UiInsuranceFund,
  UiRedemption,
  UiRedemptionStatus,
  ExpiryFuturesMarketLaunchProposal,
  PerpetualMarketLaunchProposal,
  UiOracle
} from '~/types'

export const connectWallet = (wallet: Wallet) => {
  initWeb3Strategy(wallet)
}

export const getGovParams = async (): Promise<UiGovParams> => {
  const votingParams = (
    await govConsumer.fetchParams('voting')
  ).getVotingParams()
  const depositParams = (
    await govConsumer.fetchParams('deposit')
  ).getDepositParams()
  const tallyParams = (
    await govConsumer.fetchParams('tallying')
  ).getTallyParams()

  return govParamsToUiGovParams({ votingParams, depositParams, tallyParams })
}

export const getInsuranceParams = async (): Promise<UiInsuranceParams> => {
  const params = await insuranceConsumer.fetchParams()

  return {
    defaultRedemptionNoticePeriodDuration: params
      ? params.getDefaultRedemptionNoticePeriodDuration().getSeconds()
      : 0
  }
}

export const getInsuranceFunds = async (): Promise<UiInsuranceFund[]> => {
  return insuranceFundsToUiInsuranceFunds(await insuranceFundConsumer.funds())
}

export const getOracles = async (): Promise<UiOracle[]> => {
  try {
    return oraclesToUiOracles(await oracleConsumer.oracles())
  } catch (e) {
    return []
  }
}

export const getBank = async (address: AccountAddress): Promise<UiCoin[]> => {
  return (
    await bankConsumer.fetchBalances({
      accountAddress: address
    })
  ).map((coin) => {
    return {
      amount: coin.getAmount(),
      denom: coin.getDenom()
    }
  })
}

export const getRedemptions = async ({
  denom,
  address,
  status
}: {
  address: string
  denom?: string
  status?: UiRedemptionStatus
}): Promise<UiRedemption[]> => {
  const response = await insuranceFundConsumer.redemptions({
    denom,
    address,
    status
  })

  return response.map((redemption) => ({
    redemptionId: redemption.getRedemptionId(),
    status: redemption.getStatus() as UiRedemptionStatus,
    redeemer: redemption.getRedeemer(),
    claimableRedemptionTime: redemption.getClaimableRedemptionTime(),
    redemptionAmount: redemption.getRedemptionAmount(),
    redemptionDenom: redemption.getRedemptionDenom(),
    requestedAt: redemption.getRequestedAt(),
    disbursedAmount: redemption.getDisbursedAmount(),
    disbursedDenom: redemption.getDisbursedDenom(),
    disbursedAt: redemption.getDisbursedAt()
  }))
}

export const getEstimatedRedemptions = async ({
  marketId,
  address
}: {
  marketId: string
  address: string
}): Promise<UiCoin[]> => {
  const response = await insuranceConsumer.fetchEstimatedRedemptions({
    marketId,
    address
  })

  return response.map((estimatedRedemption) => ({
    amount: cosmosSdkDecToBigNumber(estimatedRedemption.getAmount()).toFixed(),
    denom: estimatedRedemption.getDenom()
  }))
}

export const getSupply = async (): Promise<UiSupplyCoin[]> => {
  const grpcCoins = await bankConsumer.fetchSupply()

  return await grpcCoinsSupplyToUiCoins(grpcCoins)
}

export const getProposalsForStatus = async (
  status: ProposalStatusNumber,
  nextPaginationKey?: Pagination
): Promise<{
  pagination: Pagination
  proposals: UiProposal[]
}> => {
  const { proposals: grpcProposals, pagination } =
    await govConsumer.fetchProposals({
      status,
      ...(nextPaginationKey && generatePaginationFromKey(nextPaginationKey))
    })

  return {
    pagination: paginationUint8ArrayToString(pagination?.getNextKey_asB64()),
    proposals: proposalsToUiProposals(grpcProposals)
  }
}

export const getTotalProposals = async (): Promise<number | undefined> => {
  const { pagination } = await govConsumer.fetchProposals({
    status: ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED
  })

  return pagination?.getTotal()
}

export const getTotalProposalsForStatus = async (
  status: ProposalStatusNumber
): Promise<number | undefined> => {
  const { pagination } = await govConsumer.fetchProposals({
    status
  })

  return pagination?.getTotal()
}

export const getProposal = async (
  proposalId: number
): Promise<UiSingleProposal> => {
  const grcpProposal = await govConsumer.fetchProposal(proposalId)

  if (!grcpProposal) {
    throw new Error(`Proposal with proposalId ${proposalId} not found`)
  }

  const { deposits: grpcProposalDeposits } =
    await govConsumer.fetchProposalDeposits({ proposalId })
  const { votes: grpcProposalVotes } = await govConsumer.fetchProposalVotes({
    proposalId
  })
  const grpcProposalTally = await govConsumer.fetchProposalTally(proposalId)

  return proposalToUiProposal({
    grcpProposal,
    grpcProposalDeposits,
    grpcProposalVotes,
    grpcProposalTally
  })
}

export const getCosmosDetails = async (
  address: AccountAddress
): Promise<CosmosDetails> => {
  return await Promise.resolve({
    cosmosAddress: getCosmosAddressFromAddress(address)
  })
}

export const getValidators = async (): Promise<UiValidator[]> => {
  const { validators: grpcValidators } = await stakingConsumer.fetchValidators()
  const delegators = await Promise.all(grpcValidators.map((_validator) => []))
  const uiValidators = validatorsToUiValidators(grpcValidators, delegators)

  uiValidators
    .sort((a, b) => a.stakedAmount.minus(b.stakedAmount).toNumber())
    .sort((a) => (a.name.toLowerCase().includes('kc.vc') ? -1 : 1))
    .sort((a) => (a.name.toLowerCase().includes('injective') ? 1 : -1))

  return uiValidators
}

export const getUserDelegations = async (
  cosmosAddress: AccountAddress,
  nextPaginationKey?: Pagination
) => {
  const { pagination, delegations } = await stakingConsumer.fetchDelegations({
    cosmosAddress,
    ...(nextPaginationKey && generatePaginationFromKey(nextPaginationKey))
  })

  try {
    return {
      pagination: paginationUint8ArrayToString(pagination?.getNextKey_asB64()),
      delegations: grpcDelegatorsToUiDelegators(delegations)
    }
  } catch (e) {
    return {
      pagination: null,
      delegations: grpcDelegatorsToUiDelegators(delegations)
    }
  }
}

export const getUserDelegationRewards = async (
  cosmosAddress: AccountAddress
) => {
  try {
    return grpcDelegationRewardToUiReward(
      await distributionConsumer.fetchDelegatorRewards(cosmosAddress)
    )
  } catch (e) {
    return []
  }
}

export const getUserUnBondingDelegations = async (
  cosmosAddress: AccountAddress,
  nextPaginationKey?: Pagination
) => {
  const { pagination, unbondingDelegations } =
    await stakingConsumer.fetchUnbondingDelegations({
      cosmosAddress,
      ...(nextPaginationKey && generatePaginationFromKey(nextPaginationKey))
    })

  try {
    return {
      pagination: paginationUint8ArrayToString(pagination?.getNextKey_asB64()),
      unbondingDelegations:
        grpcUnBondingDelegatorsToUiUnBondingDelegators(unbondingDelegations)
    }
  } catch (e) {
    return {
      pagination: null,
      unbondingDelegations: [] as UiUnBondingDelegator[]
    }
  }
}

export const getUserReDelegations = async (
  cosmosAddress: AccountAddress,
  nextPaginationKey?: Pagination
) => {
  const { pagination, redelegations } =
    await stakingConsumer.fetchReDelegations({
      cosmosAddress,
      ...(nextPaginationKey && generatePaginationFromKey(nextPaginationKey))
    })

  try {
    return {
      pagination: paginationUint8ArrayToString(pagination?.getNextKey_asB64()),
      redelegations: grpcReDelegatorsToUiReDelegators(redelegations)
    }
  } catch (e) {
    return {
      pagination: null,
      redelegations: [] as UiReDelegator[]
    }
  }
}

export const getNetworkStatus = async () => {
  const { data: networkStatus } = await httpConsumer.getNetworkStatus()

  return networkStatusToUiNetworkStatus(networkStatus)
}

export const getTokenBasicData = async (id: string) => {
  const { data: basicData } = await httpConsumer.getTokenBasicData(id)

  return basicDataToUiBasicData(basicData)
}

export const getChartData = async (id: string, from: number, to: number) => {
  const { data: chartData } = await httpConsumer.getMarketChartRange(
    id,
    from,
    to
  )

  return chartDataToUiChartData(chartData)
}

export const getDenomBalance = async (cosmosAddress: string, denom: string) => {
  const balance = await bankConsumer.fetchBalance({
    accountAddress: cosmosAddress,
    denom
  })

  return new BigNumberInWei(balance ? balance.getAmount() : ZERO_IN_WEI)
}

export const getTokenWithBalances = async (
  accountAddress: AccountAddress,
  cosmosAddress: AccountAddress
) => {
  const getMainnetInjWithBalance = async () => {
    const $derivatives = getDerivatives()
    const $inj = $derivatives.getTokenBySymbol('INJ')
    const contracts = getContracts()
    const depositManagerAddress = contracts.depositManager.address
    const balance = await contracts.injective
      .getBalanceOf(accountAddress)
      .callAsync()
    const allowance = await contracts.injective
      .getAllowanceOf(accountAddress, depositManagerAddress)
      .callAsync()

    return {
      ...$inj,
      balance: new BigNumberInWei(balance || 0),
      type: TokenType.Token,
      isUnlocked: allowance.isGreaterThan(0)
    }
  }

  const getPreStakedInjWithBalance = async () => {
    const $derivatives = getDerivatives()
    const $inj = $derivatives.getTokenBySymbol('INJ')
    const contracts = getContracts()
    const balance = await contracts.depositManager
      .depositBalance({ address: accountAddress })
      .callAsync()

    return {
      ...$inj,
      balance: new BigNumberInWei(balance || 0),
      type: TokenType.Token,
      isUnlocked: true
    }
  }

  const getTestnetInjWithBalance = async () => {
    const $derivatives = getDerivatives()
    const $inj = $derivatives.getTokenBySymbol('INJ')
    const contracts = getTestnetContracts()
    const depositManagerAddress = contracts.peggy.address
    const balance = await contracts.injective
      .getBalanceOf(accountAddress)
      .callAsync()
    const allowance = await contracts.injective
      .getAllowanceOf(accountAddress, depositManagerAddress)
      .callAsync()

    return {
      ...$inj,
      balance: new BigNumberInWei(balance || 0),
      type: TokenType.Token,
      isUnlocked: allowance.isGreaterThan(0)
    }
  }

  const getInjectiveInjWithBalance = async () => {
    const $derivatives = getDerivatives()
    const balance = await bankConsumer.fetchBalance({
      accountAddress: cosmosAddress,
      denom: 'inj'
    })

    return {
      ...$derivatives.getTokenBySymbol('INJ'),
      balance: new BigNumberInWei(balance ? balance.getAmount() : ZERO_IN_WEI),
      type: TokenType.Token,
      isUnlocked: true
    }
  }

  const mainnetInj = await getMainnetInjWithBalance()
  const testnetInj = await getTestnetInjWithBalance()
  const preStakedInj = await getPreStakedInjWithBalance()
  const injectiveInj = await getInjectiveInjWithBalance()

  return {
    mainnetInj,
    testnetInj,
    preStakedInj,
    injectiveInj
  }
}

export const getDepositUnlockTimestamp = async ({
  address
}: {
  address: AccountAddress
}): Promise<number> => {
  const contracts = getContracts()

  const timestamp = await contracts.depositManager
    .depositUnlockTimestamp({ address })
    .callAsync()

  return parseInt(timestamp.toString())
}

export const getCompetitionEnded = async (): Promise<boolean> => {
  const contracts = getContracts()

  return await contracts.depositManager.competitionEnded().callAsync()
}

export const deposit = async ({
  amount,
  destinationAddress,
  address,
  gasPrice
}: {
  amount: BigNumberInWei
  gasPrice: BigNumberInWei
  destinationAddress: string
  address: AccountAddress
}) => {
  const contracts = getTestnetContracts()
  const web3Strategy = getWeb3Strategy()
  const ethDestinationAddress = getAddressFromCosmosAddress(destinationAddress)
  const depositForContractFunction = contracts.peggy.sendToCosmos({
    amount,
    contractAddress: contracts.injective.address,
    address: `0x${'0'.repeat(24)}${ethDestinationAddress.slice(2)}`,
    transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
  })

  const data = depositForContractFunction.getABIEncodedTransactionData()
  const gas = new BigNumberInWei(
    await depositForContractFunction.estimateGasAsync()
  )

  try {
    const txHash = await web3Strategy.sendTransaction(
      {
        from: address,
        to: contracts.peggy.address,
        gas: gas.toNumber().toString(16),
        gasPrice: new BigNumber(
          gasPrice.lt(TESTNET_GAS_PRICE)
            ? gasPrice.toNumber()
            : TESTNET_GAS_PRICE.toNumber()
        )
          .toNumber()
          .toString(16),
        data
      },
      { address, chainId: TESTNET_CHAIN_ID }
    )
    await waitTransactionSuccess(txHash)
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const sendAssetToCosmos = async ({
  amount,
  token,
  destinationAddress,
  address,
  gasPrice
}: {
  token: TokenWithBalance
  amount: BigNumberInWei
  gasPrice: BigNumberInWei
  destinationAddress: string
  address: AccountAddress
}) => {
  const contracts = getTestnetContracts()
  const web3Strategy = getWeb3Strategy()
  const ethDestinationAddress = getAddressFromCosmosAddress(destinationAddress)
  const depositForContractFunction = contracts.peggy.sendToCosmos({
    amount,
    contractAddress: token.address,
    address: `0x${'0'.repeat(24)}${ethDestinationAddress.slice(2)}`,
    transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
  })

  const data = depositForContractFunction.getABIEncodedTransactionData()
  const gas = new BigNumberInWei(
    await depositForContractFunction.estimateGasAsync()
  )

  try {
    const txHash = await web3Strategy.sendTransaction(
      {
        from: address,
        to: contracts.peggy.address,
        gas: gas.toNumber().toString(16),
        gasPrice: new BigNumber(
          gasPrice.lt(TESTNET_GAS_PRICE)
            ? gasPrice.toNumber()
            : TESTNET_GAS_PRICE.toNumber()
        )
          .toNumber()
          .toString(16),
        data
      },
      { address, chainId: TESTNET_CHAIN_ID }
    )
    await waitTransactionSuccess(txHash)
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const stake = async ({
  amount,
  address,
  gasPrice
}: {
  amount: BigNumberInWei
  gasPrice: BigNumberInWei
  address: AccountAddress
}) => {
  const contracts = getContracts()
  const web3Strategy = getWeb3Strategy()
  const depositForContractFunction = contracts.depositManager.deposit({
    amount,
    transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
  })

  const data = depositForContractFunction.getABIEncodedTransactionData()
  const gas = new BigNumberInWei(
    await depositForContractFunction.estimateGasAsync()
  )

  try {
    const txHash = await web3Strategy.sendTransaction(
      {
        from: address,
        to: contracts.depositManager.address,
        gas: gas.toNumber().toString(16),
        gasPrice: gasPrice.toNumber().toString(16),
        data
      },
      { address, chainId: MAINNET_CHAIN_ID }
    )
    await waitTransactionSuccess(txHash)
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const unStake = async ({
  amount,
  address,
  gasPrice
}: {
  amount: BigNumberInWei
  gasPrice: BigNumberInWei
  address: AccountAddress
}) => {
  const contracts = getContracts()
  const web3Strategy = getWeb3Strategy()
  const withdrawContractFunction = contracts.depositManager.withdraw({
    address,
    amount,
    transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
  })

  const data = withdrawContractFunction.getABIEncodedTransactionData()
  const gas = new BigNumberInWei(
    await withdrawContractFunction.estimateGasAsync()
  )

  try {
    const txHash = await web3Strategy.sendTransaction(
      {
        from: address,
        to: contracts.depositManager.address,
        gas: gas.toNumber().toString(16),
        gasPrice: gasPrice.toNumber().toString(16),
        data
      },
      { address, chainId: MAINNET_CHAIN_ID }
    )
    await waitTransactionSuccess(txHash)
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const withdraw = async ({
  address,
  cosmosAddress,
  destinationAddress,
  amount
}: {
  amount: BigNumberInWei
  address: AccountAddress
  destinationAddress: string
  cosmosAddress: AccountAddress
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = PeggyComposer.withdraw({
    address: destinationAddress,
    denom: 'inj',
    cosmosAddress,
    amount
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const delegate = async ({
  address,
  cosmosAddress,
  validatorAddress,
  amount
}: {
  amount: BigNumberInWei
  address: AccountAddress
  validatorAddress: string
  cosmosAddress: AccountAddress
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = StakingComposer.delegate({
    validatorAddress,
    cosmosAddress,
    denom: 'inj',
    amount
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const reDelegate = async ({
  address,
  cosmosAddress,
  destinationValidatorAddress,
  sourceValidatorAddress,
  amount,
  denom
}: {
  amount: BigNumberInWei
  denom: string
  address: AccountAddress
  destinationValidatorAddress: string
  sourceValidatorAddress: string
  cosmosAddress: AccountAddress
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = StakingComposer.reDelegate({
    amount,
    denom,
    destinationValidatorAddress,
    sourceValidatorAddress,
    cosmosAddress
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const requestRedemption = async ({
  address,
  cosmosAddress,
  marketId,
  amount,
  denom
}: {
  amount: BigNumberInWei
  address: AccountAddress
  marketId: string
  denom: string
  cosmosAddress: AccountAddress
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = InsuranceComposer.requestRedemption({
    marketId,
    denom,
    address: cosmosAddress,
    amount: amount.toFixed()
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const underwrite = async ({
  address,
  cosmosAddress,
  marketId,
  amount,
  denom
}: {
  amount: BigNumberInWei
  address: AccountAddress
  marketId: string
  denom: string
  cosmosAddress: AccountAddress
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = InsuranceComposer.underwrite({
    marketId,
    denom,
    address: cosmosAddress,
    amount: amount.toFixed()
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const unbond = async ({
  address,
  cosmosAddress,
  validatorAddress,
  amount
}: {
  amount: BigNumberInWei
  address: AccountAddress
  validatorAddress: string
  cosmosAddress: AccountAddress
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = StakingComposer.unbond({
    validatorAddress,
    denom: 'inj',
    cosmosAddress,
    amount
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const withdrawDelegatorReward = async ({
  address,
  cosmosAddress,
  validatorAddress
}: {
  address: AccountAddress
  validatorAddress: string
  cosmosAddress: AccountAddress
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = DistributionComposer.withdrawDelegatorReward({
    validatorAddress,
    delegatorAddress: cosmosAddress
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const setTokenAllowanceForNetwork = async ({
  address,
  amount,
  gasPrice,
  contractSpenderAddress
}: {
  address: AccountAddress
  amount: BigNumberInWei
  chainId: ChainId
  gasPrice: BigNumberInWei
  contractSpenderAddress: string
}) => {
  const contracts = getContracts()
  const web3Strategy = getWeb3Strategy()

  const setAllowanceOfContractFunction = contracts.injective.setAllowanceOf({
    amount,
    contractAddress: contractSpenderAddress,
    transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
  })

  const data = setAllowanceOfContractFunction.getABIEncodedTransactionData()
  const gas = new BigNumberInWei(
    await setAllowanceOfContractFunction.estimateGasAsync()
  )

  try {
    const txHash = await web3Strategy.sendTransaction(
      {
        from: address,
        to: contracts.injective.address,
        gas: gas.toNumber().toString(16),
        gasPrice: gasPrice.toNumber().toString(16),
        data
      },
      { address, chainId: MAINNET_CHAIN_ID }
    )
    await waitTransactionSuccess(txHash)
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const setTokenAllowanceForMainnet = async ({
  address,
  amount,
  gasPrice
}: {
  address: AccountAddress
  amount: BigNumberInWei
  gasPrice: BigNumberInWei
}) => {
  const contracts = getContracts()
  const contractSpenderAddress = contracts.depositManager.address
  const web3Strategy = getWeb3Strategy()

  const setAllowanceOfContractFunction = contracts.injective.setAllowanceOf({
    amount,
    contractAddress: contractSpenderAddress,
    transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
  })

  const data = setAllowanceOfContractFunction.getABIEncodedTransactionData()
  const gas = new BigNumberInWei(
    await setAllowanceOfContractFunction.estimateGasAsync()
  )

  try {
    const txHash = await web3Strategy.sendTransaction(
      {
        from: address,
        to: contracts.injective.address,
        gas: gas.toNumber().toString(16),
        gasPrice: gasPrice.toNumber().toString(16),
        data
      },
      { address, chainId: MAINNET_CHAIN_ID }
    )
    await waitTransactionSuccess(txHash)
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const setTokenAllowanceForTestnet = async ({
  address,
  amount,
  gasPrice
}: {
  gasPrice: BigNumberInWei
  address: AccountAddress
  amount: BigNumberInWei
}) => {
  const contracts = getTestnetContracts()
  const contractSpenderAddress = contracts.peggy.address
  const web3Strategy = getWeb3Strategy()

  const setAllowanceOfContractFunction = contracts.injective.setAllowanceOf({
    amount,
    contractAddress: contractSpenderAddress,
    transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
  })

  const data = setAllowanceOfContractFunction.getABIEncodedTransactionData()
  const gas = new BigNumberInWei(
    await setAllowanceOfContractFunction.estimateGasAsync()
  )

  try {
    const txHash = await web3Strategy.sendTransaction(
      {
        from: address,
        to: contracts.injective.address,
        gas: gas.toNumber().toString(16),
        gasPrice: new BigNumber(
          gasPrice.lt(TESTNET_GAS_PRICE)
            ? gasPrice.toNumber()
            : TESTNET_GAS_PRICE.toNumber()
        )
          .toNumber()
          .toString(16),
        data
      },
      { address, chainId: TESTNET_CHAIN_ID }
    )
    await waitTransactionSuccess(txHash)
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const getContractBalance = async ({
  address,
  contractAddress,
  gasPrice
}: {
  address: AccountAddress
  contractAddress: string
  gasPrice: BigNumberInWei
}): Promise<TokenWithBalance> => {
  const web3Strategy = getWeb3Strategy()
  const contracts = getTestnetContracts()
  const erc20Contract = new BaseCurrencyContract({
    web3Strategy,
    address: contractAddress,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const balance = await erc20Contract.getBalanceOf(address).callAsync()
    const allowance = await erc20Contract
      .getAllowanceOf(address, contracts.peggy.address)
      .callAsync()

    const tokenWithBalance = {
      symbol: await erc20Contract.getSymbol().callAsync(),
      name: await erc20Contract.getName().callAsync(),
      decimals: parseInt(await erc20Contract.getDecimals().callAsync()),
      displayDecimals: 4,
      address: contractAddress,
      balance: new BigNumberInWei(balance || 0),
      type: TokenType.Token
    }

    if (allowance.gt(0)) {
      return { ...tokenWithBalance, isUnlocked: true }
    }

    const setAllowanceOfContractFunction = erc20Contract.setAllowanceOf({
      amount: UNLIMITED_ALLOWANCE_IN_BASE_UNITS,
      contractAddress: contracts.peggy.address,
      transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
    })

    const data = setAllowanceOfContractFunction.getABIEncodedTransactionData()
    const gas = new BigNumberInWei(
      await setAllowanceOfContractFunction.estimateGasAsync()
    )

    try {
      const txHash = await web3Strategy.sendTransaction(
        {
          from: address,
          to: contractAddress,
          gas: gas.toNumber().toString(16),
          gasPrice: new BigNumber(
            gasPrice.lt(TESTNET_GAS_PRICE)
              ? gasPrice.toNumber()
              : TESTNET_GAS_PRICE.toNumber()
          )
            .toNumber()
            .toString(16),
          data
        },
        { address, chainId: TESTNET_CHAIN_ID }
      )

      await waitTransactionSuccess(txHash)

      return { ...tokenWithBalance, isUnlocked: true }
    } catch (error) {
      throw new Web3Exception(error.message)
    }
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const setContractAllowance = async ({
  address,
  contractAddress,
  chainId,
  gasPrice
}: {
  address: AccountAddress
  contractAddress: string
  chainId: ChainId
  gasPrice: BigNumberInWei
}): Promise<TokenWithBalance> => {
  const web3Strategy = getWeb3Strategy()
  const contracts =
    parseInt(chainId.toString()) === ChainId.Kovan
      ? getTestnetContracts()
      : getContracts()
  const erc20Contract = new BaseCurrencyContract({
    web3Strategy,
    address: contractAddress,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const balance = await erc20Contract.getBalanceOf(address).callAsync()
    const allowance = await erc20Contract
      .getAllowanceOf(address, contracts.peggy.address)
      .callAsync()

    const tokenWithBalance = {
      symbol: await erc20Contract.getSymbol().callAsync(),
      name: await erc20Contract.getName().callAsync(),
      decimals: parseInt(await erc20Contract.getDecimals().callAsync()),
      displayDecimals: 4,
      address: contractAddress,
      balance: new BigNumberInWei(balance || 0),
      type: TokenType.Token
    }

    if (allowance.gt(0)) {
      return { ...tokenWithBalance, isUnlocked: true }
    }

    const setAllowanceOfContractFunction = erc20Contract.setAllowanceOf({
      amount: UNLIMITED_ALLOWANCE_IN_BASE_UNITS,
      contractAddress: contracts.peggy.address,
      transactionOptions: getTransactionOptions(gasPrice.toFixed(), address)
    })

    const data = setAllowanceOfContractFunction.getABIEncodedTransactionData()
    const gas = new BigNumberInWei(
      await setAllowanceOfContractFunction.estimateGasAsync()
    )

    try {
      const txHash = await web3Strategy.sendTransaction(
        {
          from: address,
          to: contractAddress,
          gas: gas.toNumber().toString(16),
          gasPrice: new BigNumber(
            gasPrice.lt(TESTNET_GAS_PRICE)
              ? gasPrice.toNumber()
              : TESTNET_GAS_PRICE.toNumber()
          )
            .toNumber()
            .toString(16),
          data
        },
        { address, chainId: TESTNET_CHAIN_ID }
      )

      await waitTransactionSuccess(txHash)

      return { ...tokenWithBalance, isUnlocked: true }
    } catch (error) {
      throw new Web3Exception(error.message)
    }
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const getContractBalanceAndAllowance = async ({
  address,
  contractAddress
}: {
  address: AccountAddress
  contractAddress: string
  gasPrice: BigNumberInWei
}): Promise<TokenWithBalance> => {
  const web3Strategy = getWeb3Strategy()
  const contracts = getTestnetContracts()
  const erc20Contract = new BaseCurrencyContract({
    web3Strategy,
    address: contractAddress,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const balance = await erc20Contract.getBalanceOf(address).callAsync()
    const allowance = await erc20Contract
      .getAllowanceOf(address, contracts.peggy.address)
      .callAsync()

    const tokenWithBalance = {
      symbol: await erc20Contract.getSymbol().callAsync(),
      name: await erc20Contract.getName().callAsync(),
      decimals: parseInt(await erc20Contract.getDecimals().callAsync()),
      displayDecimals: 4,
      address: contractAddress,
      balance: new BigNumberInWei(balance || 0),
      type: TokenType.Token
    }

    return { ...tokenWithBalance, isUnlocked: allowance.gt(0) }
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const depositToProposal = async ({
  amount,
  address,
  cosmosAddress,
  proposalId
}: {
  amount: BigNumberInWei
  address: AccountAddress
  cosmosAddress: AccountAddress
  proposalId: number
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = GovernanceComposer.deposit({
    denom: 'inj',
    amount: amount.toFixed(),
    proposalId,
    depositor: cosmosAddress
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const voteToProposal = async ({
  address,
  vote,
  cosmosAddress,
  proposalId
}: {
  vote: VoteOptionNumber
  address: AccountAddress
  cosmosAddress: AccountAddress
  proposalId: number
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = GovernanceComposer.vote({
    proposalId,
    vote,
    voter: cosmosAddress
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,
      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const proposePerpetualMarketLaunch = async ({
  market,
  address,
  cosmosAddress,
  deposit
}: {
  market: PerpetualMarketLaunchProposal
  address: AccountAddress
  cosmosAddress: AccountAddress
  deposit: UiCoin
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = ExchangeProposalComposer.perpetualMarketLaunch({
    market,
    deposit: {
      amount: new BigNumber(deposit.amount),
      denom: deposit.denom
    },
    proposer: cosmosAddress
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,

      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const proposeExpiryFuturesMarketLaunch = async ({
  market,
  address,
  cosmosAddress,
  deposit
}: {
  market: ExpiryFuturesMarketLaunchProposal
  address: AccountAddress
  cosmosAddress: AccountAddress
  deposit: UiCoin
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = ExchangeProposalComposer.expiryFuturesMarketLaunch({
    market,
    deposit: {
      amount: new BigNumber(deposit.amount),
      denom: deposit.denom
    },
    proposer: cosmosAddress
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,

      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const proposeSpotMarketLaunch = async ({
  market,
  address,
  cosmosAddress,
  deposit
}: {
  market: SpotMarketLaunchProposal
  address: AccountAddress
  cosmosAddress: AccountAddress
  deposit: UiCoin
}) => {
  const web3Strategy = getWeb3Strategy()
  const message = ExchangeProposalComposer.spotMarketLaunch({
    market,
    deposit: {
      amount: new BigNumber(deposit.amount),
      denom: deposit.denom
    },
    proposer: cosmosAddress
  })

  const txResponse = await transactionConsumer.prepareTxRequest({
    address,
    message,
    chainId: TESTNET_CHAIN_ID
  })

  try {
    const signature = await web3Strategy.signTypedDataV4(
      txResponse.getData(),
      address
    )

    return await transactionConsumer.broadcastTxRequest({
      signature,
      message,

      txResponse,
      chainId: TESTNET_CHAIN_ID
    })
  } catch (error) {
    throw new Web3Exception(error.message)
  }
}

export const subscribeToBlockHeightUpdate = (
  onUpdate: (blockNumber: number) => void
) => {
  const web3Strategy = getWeb3Strategy()
  const web3 = web3Strategy.getWeb3WsForChainId(CHAIN_ID)
  const blockSubscription = web3.eth.subscribe('newBlockHeaders')

  blockSubscription
    .subscribe((_error: any, _result: any) => {
      //
    })
    .on('data', (blockHeader: any) => {
      if (!blockHeader || !blockHeader.number) {
        return
      }

      onUpdate(blockHeader.number)
    })
}

export enum Reward {
  Claim = 'claim',
  ReDelegate = 're-delegate',
  Governance = 'governance',
  NewMarket = 'market',
  MarketProposal = 'market-proposal',
  Special = 'special'
}

export const getRewardsObjects = () => {
  const rewards = {
    [Reward.Claim]: false,
    [Reward.ReDelegate]: false,
    [Reward.Governance]: false,
    [Reward.NewMarket]: false,
    [Reward.MarketProposal]: false,
    [Reward.Special]: false
  } as Record<Reward, boolean>

  const rewardsBoost = {
    [Reward.Claim]: 0.005,
    [Reward.ReDelegate]: 0.005,
    [Reward.Governance]: 0.02,
    [Reward.NewMarket]: 0.03,
    [Reward.MarketProposal]: 0.05,
    [Reward.Special]: 0.03
  } as Record<Reward, number>

  return { rewards, rewardsBoost }
}
